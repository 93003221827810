html, body, #root {
  height: 100%;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.ne-layout-wrapper {
  height: 100%
}

.ne-center {
  margin: auto;
}

.ne-header-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-flow: column;
  padding-inline-start: 0px;
  height: fit-content;
}

.ne-header-list-item-selected {
  text-decoration: underline;
}

.ne-header-title:hover, .ne-logo:hover, .ne-header-list-item:hover {
  color: #555555;
  cursor: pointer;
}

.ne-logo {
  height: 50px;
  width: 50px;
}

/** RELEASE DETAIL **/
@media (min-width: 700px) {
  .ne-release-detail {
    padding-top: 25px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .ne-release-left {
    padding-right: 25px;
  }

  .ne-release-details {
    padding-left: 25px;
  }
}

@media (max-width: 700px) {
  .ne-release-detail {
    display: grid;
    grid-template-rows: 215px 1fr;
  }
}

.ne-release-detail-header {
  display: flex;
  flex-direction: column;
}

.ne-release-detail-play-button {
  font-size: 10px;
  cursor: pointer;
  width: fit-content;
}

.ne-release-detail-play-button:hover {
  text-decoration: underline;
}

.ne-release-detail-purchase-button {
  color: white;
  height: 15px;
  font-size: 10px;
  width: fit-content;
}

.ne-release-detail-purchase-button:hover {
  color: white;
}

.ne-release-detail-bc {
  margin-bottom: 10px;
}

.ne-release-image {
  padding-bottom: 25px;
}

.ne-release-detail-zine {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

/** RELEASES **/
@media (min-width: 700px) {
  .ne-releases {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ne-release-section {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1 / span 3;
    display: grid;
    margin-bottom: 10px;
  }

  .ne-release-section-title {
    grid-column: 1 / span 3;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .ne-releases {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ne-release-section {
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / span 2;
    display: grid;
    margin-bottom: 10px;
  }

  .ne-release-section-title {
    grid-column: 1 / span 2;
    text-align: center;
  }
}

.ne-release-brief {
  display: grid;
  grid-template-rows: 80% 10% 10%;
  justify-self: center;
  width: fit-content;
  padding: 5px;
}

.ne-release-brief:hover {
  background-color: #bbbbbb;
  cursor: pointer;
}

.ne-release-name, .ne-release-artist {
  min-width: 125px;
  text-align: center;
}

/** ARTIST DETAIL **/
.ne-artist-detail {
  display: grid;
  grid-template-rows: 225px 1fr;
  justify-items: center;
  width: 95%;
}

.ne-artist-image {
  padding-bottom: 25px;
}

@media (max-width: 700px) {
  .ne-artist-detail-featured-releases {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  .ne-artist-detail-featured-releases .ne-release-name {
    min-width: 0px;
  }
}

@media (min-width: 700px) {
  .ne-artist-detail-featured-releases {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/** ARTISTS **/
@media (min-width: 700px) {
  .ne-artists {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1 / span 3;
    display: grid;
    margin-bottom: 10px;
  }
}

@media (max-width: 700px) {
  .ne-artists {
    align-items: center;
    grid-template-columns: 1fr;
    display: grid;
    margin-bottom: 20px;
  }
}

.ne-artist-brief {
  display: grid;
  grid-template-rows: 80% 20%;
  justify-self: center;
  width: 90%;
  padding: 5px;
}

.ne-artist-brief:hover {
  background-color: #bbbbbb;
  cursor: pointer;
}

.ne-artist-brief-name {
  min-width: 125px;
  text-align: center;
}

.ne-artist-brief-image {
  justify-content: center;
  display: flex;
}

.ne-artist-detail-description {
  white-space: pre-wrap;
}

/** RELEASE **/
.ne-release-name, .ne-release-artist {
  font-size: 60%;
  justify-content: center;
}

.ne-release-detail-description {
  margin-top: 10px;
  white-space: pre-wrap;
}

.ne-release-detail-date {
  font-size: 10px;
  margin-bottom: 10px;
}

.ne-release-left {
  justify-content: center;
  margin-bottom: 25px;
}

.ne-release-image {
  justify-content: center;
  display: flex;
}

/** THOUGHTS **/
@media (min-width: 700px) {
  .ne-thoughts {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.ne-thought-title {
  font-size: 18px;
  font-weight: bold;
  max-width: fit-content;
}

.ne-thought-date {
  font-size: 10px;
  margin-bottom: 5px;
  font-style: italic;
}

/** ABOUT **/
@media (min-width: 700px) {
  .ne-about {
    margin-left: 100px;
    margin-right: 100px;
  }  
}

.ne-about {
  text-align: center;
}

.ne-bio-name {
  font-size: xx-large;
  font-weight: 100;
  font-family: serif;
}

.ne-bio-desc {
  white-space: pre-wrap;
}

.ne-bio-email {
  font-size: x-small;
}

.ne-bio-gram {
  margin-right: 5px;
}

.ne-about-general {
  margin-top: 15px;
  font-size: large;
}

/** FOOTER **/
.ne-footer-wrap {
  grid-column: 1 / span 3;
  width: 100%;
  margin-bottom: 10px;
  margin-top: -50px;
}

.ne-footer-spacer {
  display: block;
  height: 25px;
  width: 100%;
}

@media (min-width: 700px) {
  .ne-footer {
    width: 100%;
    display: grid;
    grid-template-columns: 10fr 1fr 1fr 1fr 1fr 10fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}

@media (max-width: 700px) {
  .ne-footer {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 1fr 1fr 5fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}

.ne-audio-player-wrapper {
  grid-column: 1 / span 3;
  width: 100%;
}

/** ZOOMABLE IMAGE **/
.ne-zoomable-image:hover {
  /* cursor: pointer; */
}

.ne-zoomable-image {
  max-width: 100%;
}

.ne-carousel {
  max-width:  50vw;
  max-height:  50vh;
}

/** ZINES **/
@media (min-width: 700px) {
  .ne-zines {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1 / span 3;
    display: grid;
    margin-bottom: 10px;
  }
}

@media (max-width: 700px) {
  .ne-zines {
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / span 2;
    display: grid;
    margin-bottom: 10px;
  }
}

.ne-zine-brief {
  display: grid;
  grid-template-rows: 80% 20%;
  justify-self: center; 
  padding: 5px;
}

.ne-zine-brief:hover {
  background-color: #bbbbbb;
  cursor: pointer;
}

.ne-zine-brief-image {
  justify-content: center;
  display: flex;
}

.ne-zine-brief-name {
  min-width: 125px;
  text-align: center;
}

.ne-zine-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ne-zine-page-image-scrollable {
  margin-bottom: 15px;
  max-width:100%;
  max-height:100%;
}

.ne-zine-button {
  justify-self: center;
  cursor: pointer;
}

.ne-fugue-image {
  max-height: 700px;
}

body {
  color: #eeeeee;
  background-color: #111111;
}

.rhap_container {
  color: #111111;
}
.rhap_background-color {
  color: #111111;
}

.ne-stars:hover {
  cursor: pointer;
}

.ne-fugue-next {
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 5px;
}

.ne-justify-text {
  text-align: justify;
  text-justify: inter-word;
  white-space: pre-wrap;
}

.ne-center-text {
  text-align: center;
}

.ne-left-text {
  text-align: left;
  white-space: pre-wrap;
}

.ne-link {
  color: white;
}

.ne-link:hover {
  color: #555555;
  text-decoration-color: #555555;
}

.ne-current-tab {
  color: #bbbbbb;
}

.ne-current-tab:hover {
  color: #555555;
  text-decoration-color: #555555;
}

.ne-zine-preview {
  width: fit-content;
}

.ne-xx-large {
  font-size: xx-large;
}

.ne-thought-content-preview {
  margin-top: 10px;
  margin-right: 10px;
}

/** DESKTOP ONLY **/
@media (min-width: 700px) {
  .ne-content-wrapper {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-rows: 90% 8% 2%;
    justify-items: center;
  }

  .ne-header {
    padding-top: 2%;
    display: grid;
    grid-template-columns: 50% 10% 40%;
    justify-items: center;
    align-items: center;
  }

  .ne-thought-audio {
    width: 75%;
    margin: auto;
  }

  .ne-thought {
    white-space: pre-wrap;
    margin-bottom: 100px;
  }

  .ne-thought-image {
    max-width: 750px;
  }

  .ne-thought-embed {
    text-align: center;
  }

  .ne-thought-embed > * {
    max-width: 750px;
  }

  .ne-thought-cover-image {
    float: right;
    margin-left: 10px;
    max-width: 250px;
  }

  .ne-content {
    width: 60%;
    min-height: 100%;
    margin: auto;
    padding-bottom: 50px;
  }
}

/** MOBILE ONLY **/
@media (max-width: 700px) {
  .ne-content-wrapper {
    display: grid;
    grid-template-columns: 5% 90% 5%;
    grid-template-rows: 90% 8% 2%;
    justify-items: center;
  }

  .ne-header {
    display: grid;
    grid-template-rows: 25% 25% 1fr;
    justify-items: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .ne-thought-audio {
    margin: auto;
  }

  .ne-thought {
    white-space: pre-wrap;
    margin-bottom: 25px;
  }

  .ne-thought-image {
    max-width: 300px;
  }

  .ne-thought-embed {
    text-align: center;
  }

  .ne-thought-embed > * {
    max-width: 300px;
  }

  .ne-thought-cover-image {
    max-width: 250px;
    display: block;
    margin: 0 auto;
  }

  .ne-content {
    width: 80%;
    min-height: 100%;
    margin: auto;
    padding-bottom: 50px;
  }
}

.ne-caption {
  font-size: smaller;

}